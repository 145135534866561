<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="showCreateUserLevelModal()">
        <PlusOutlined />新增
      </a-button>
    </div>
    <div class="table-search">
      <a-form :model="formState" layout="inline" autocomplete="off" @submit="handleRefresh">
        <a-form-item label="等级名称">
          <a-input v-model:value="formState.level_name" placeholder="等级名称" />
        </a-form-item>
        <a-form-item class="search-btn">
          <a-button type="primary" html-type="submit">搜索</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-table rowKey="level_id" :columns="columns" :data-source="data" :pagination="Refpagination" @change="handleRefresh">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a @click="showUpdateUserLevelModal(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="showDeleteConfirm(record)">删除</a>
          </span>
        </template>
      </template>
    </a-table>
    <create-user-level ref="RefCreateUserLevel" @handleSubmit="handleRefresh" />
    <update-user-level ref="RefUpdateUserLevel" @handleSubmit="handleRefresh" />
  </div>
</template>
<script>
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode, defineComponent, reactive, ref, onMounted } from "vue";
import { Modal, message } from "ant-design-vue";
import * as Api from "@/views/user/api";
import CreateUserLevel from "./modules/level/CreateUserLevel.vue";
import UpdateUserLevel from "./modules/level/UpdateUserLevel.vue";
const columns = [
  {
    title: "ID",
    dataIndex: "level_id",
    width: "10%",
  },
  {
    title: "等级名称",
    dataIndex: "level_name",
    width: "12%",
  },
  {
    title: "等级说明",
    dataIndex: "remark",
    width: "10%",
  },
  {
    title: "成长值",
    dataIndex: "growth",
    width: "10%",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "20%",
  },
];

export default defineComponent({
  components: {
    PlusOutlined,
    CreateUserLevel,
    UpdateUserLevel,
  },
  setup() {
    const formState = reactive({
      level_name: "",
      grade_id: 0,
      betweenTime: "",
    });

    const gradeList = ref([{ name: "默认等级", grade_id: 0 }]);

    const data = ref([]);
    const Refpagination = ref({});

    // 添加用户等级
    const RefCreateUserLevel = ref();
    const showCreateUserLevelModal = () => {
      RefCreateUserLevel.value.showFromModal();
    };

    // 编辑用户
    const RefUpdateUserLevel = ref();
    const showUpdateUserLevelModal = (item) => {
      RefUpdateUserLevel.value.showFromModal(item);
    };

    // 刷新用户列表数据
    const handleRefresh = (pagination) => {
      if (typeof pagination !== "undefined") {
        formState.page = pagination.current;
      }
      Api.user_level_list(formState).then((res) => {
        Refpagination.value.current = res.data.list.current_page;
        Refpagination.value.pageSize = res.data.list.per_page;
        Refpagination.value.total = res.data.list.total;
        data.value = res.data.list.data;
      });
    };

    const showDeleteConfirm = (record) => {
      Modal.confirm({
        title: "确定要删除【" + record.level_name + "】吗?",
        icon: createVNode(ExclamationCircleOutlined),
        content: "删除用户等级后将无法恢复，请谨慎操作！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          Api.user_level_del({ id: record.level_id }).then((res) => {
            message.success(res.message);
            handleRefresh();
          });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    };

    onMounted(() => {
      handleRefresh();
    });

    return {
      value1: ref(),
      formState,
      gradeList,
      columns,
      data,
      Refpagination,
      RefCreateUserLevel,
      RefUpdateUserLevel,
      showCreateUserLevelModal,
      showUpdateUserLevelModal,
      showDeleteConfirm,
      handleRefresh,
    };
  },
});
</script>